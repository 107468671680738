import * as bootstrap from "bootstrap";
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/swiper.css";


// var myCarousel = document.querySelector('#myCarousel')
// var carousel = new bootstrap.Carousel(myCarousel, {
//   interval: 100000
// })

// $('.carousel .carousel-item').each(function(){
//     var minPerSlide = 4;
//     var next = $(this).next();
//     if (!next.length) {
//     next = $(this).siblings(':first');
//     }
//     next.children(':first-child').clone().appendTo($(this));

//     for (var i=0;i<minPerSlide;i++) {
//         next=next.next();
//         if (!next.length) {
//         	next = $(this).siblings(':first');
//       	}

//         next.children(':first-child').clone().appendTo($(this));
//       }
// });
var swiper = new Swiper(".mySwiper", {
  slidesPerView: 1.1,
  spaceBetween: 30,
  modules: [Navigation, Pagination],
  breakpoints: {
    // when window width is >= 320px
    768: {
      slidesPerView: 2.1,
      spaceBetween: 20
    },
    // when window width is >= 480px
    992: {
      slidesPerView: 3.1,
      spaceBetween: 30
    },
  },
  navigation: {
    nextEl: '.arrow-left',
    prevEl: '.arrow-right',
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});
